import APIService from "./APIService";


class AuthService extends APIService
{

   login = async ({email, password}:any) => {
      const result = await this.post({email, password}, 'auth/login');
        
      return result ? result.data : null;
   }

   forgotPassword = async ({email}:any) => {
      const result = await this.post({email}, 'auth/forgot-password');
        
      return result ? result.data : null;
   }

   resetPassword = async ({token, new_password, confirm_password}:any) => {
      const result = await this.post({token, new_password, confirm_password}, 'auth/reset-password');
        
      return result ? result.data : null;
   }

   isLoggedIn = () => {
      return localStorage.getItem('access_token') ? true : false;
   }


   test = async () => {
      const result = await this.get('test-api-call');

      return result ? result.data : null;
   }


}

export default AuthService