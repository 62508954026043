import React, { useEffect, useState } from 'react';
import { Menu, Row } from 'antd';
import {useHistory, useLocation } from 'react-router-dom';
import { MenuFoldOutlined,
    MenuUnfoldOutlined,
    DashboardOutlined,
    BarChartOutlined,
    } from '@ant-design/icons';
import {ReactComponent as PesoLogo} from '../../../assets/img/svg/IconPeso.svg'

const SideMenuView = (props:any) => {   
    const location = useLocation();
    const history = useHistory();

    const [currentPath, setCurrentPath] = useState(location.pathname);
   
    useEffect(() => {
        setCurrentPath(location.pathname);
    }, [location.pathname]);
   
    const toggleSidebar = () => {
        props.collapseToggle(!props.siderCollapsed)
    }

   const onToggleMenus = (action:string) => {
        setCurrentPath(action);
        history.push(action); 
   }

   return (
        <Row>
            <Row align="middle" justify="end" style={{height: 58, width: '90%'}}>
                {props.siderCollapsed ? <MenuUnfoldOutlined onClick={toggleSidebar} /> : <MenuFoldOutlined onClick={toggleSidebar}/>}    
            </Row>
            <Menu className="text-primary" mode="inline" defaultSelectedKeys={[currentPath === '/' ? '/sales-report' : currentPath ]}>
                {/* <Menu.Item className="text-primary" key="/dashboard" onClick={() => onToggleMenus('/dashboard')}>
                    <DashboardOutlined />
                    <span className="text-primary">Dashboard</span>
                </Menu.Item> */}

                <Menu.Item className="text-primary" key="/sales-report" onClick={() => onToggleMenus('/sales-report')}>
                    <BarChartOutlined />
                    <span className="text-primary">Sales Report</span>
                </Menu.Item>
                <Menu.Item className="text-primary" key="/receivables" onClick={() => onToggleMenus('/receivables')}>
                <PesoLogo className="margin-right-s"  />
                    <span className="text-primary">Receivables</span>
                </Menu.Item>
                
            </Menu>
        </Row>
    );
}

export default SideMenuView